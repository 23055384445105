import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useField } from 'formik'
import * as React from 'react'
import PopoverComponent from 'src/views/components/PopoverComponent'

import { isNumber } from 'src/helpers/fns'
import Loader from '../Loader'
import { useFormData } from './formik/Form'

interface Props {
  readonly className?: string
  readonly placeholder?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly loading: boolean
  readonly label?: string
  readonly value?: string
  readonly submit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  readonly submitedScore: number | null | undefined
}

export default function ScoreField({ loading, submit, submitedScore, disabled, ...props }: Props): React.ReactElement {
  const [status, setStatus] = React.useState<string | null>(null)
  const [field, , helpers] = useField({ name: props.name })
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []

  React.useEffect(() => {
    if (formData.submitted) {
      if (errors.length > 0 || formData.errors.general.length > 0) {
        setStatus('error')
      } else {
        setStatus('success')
      }
    } else if (loading) {
      setStatus('loading')
    }
  }, [errors.length, formData.errors.general.length, formData.submitted, loading])
  const [inputValue, setInputValue] = React.useState(typeof field.value === 'number' ? field.value.toFixed(2) : '')

  const handleInput = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (isNumber(value)) {
      setInputValue(value)
    }
  }, [])
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (isNumber(value)) {
        helpers.setValue(value)
      }
    },
    [helpers]
  )
  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
        const step = 1
        const value = parseFloat(inputValue)
        const newValue = e.key === 'ArrowDown' ? value - step : value + step
        const fixedValue = newValue.toFixed(2)
        setInputValue(fixedValue)
        helpers.setValue(fixedValue)
      }
    },
    [helpers, inputValue]
  )

  return (
    <div className="mb-3">
      <div className="relative max-w-full grow basis-0">
        <input
          {...field}
          {...props}
          disabled={disabled}
          value={inputValue}
          onInput={handleInput}
          className={classnames(
            'w-full max-w-[100px] rounded-[4px] border border-borderColor bg-primaryWhite py-2.5 pl-3 text-bodyText text-primaryTextColor',
            {
              'border-primaryRed': status === 'error',
              'border-primaryBlue': status === 'success',
              'opacity-50': disabled,
            }
          )}
          style={{ padding: '6px 20px 6px 7px' }}
          onBlur={() => {
            if (submitedScore !== field.value) {
              submit()
            }
          }}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {status === 'error' ? (
          <div className="absolute max-w-[276px] rounded p-1 text-caption text-lightSecondaryWarning">
            <div className="">
              {errors.map((error, i) => (
                <div key={i}>{error}</div>
              ))}
              {formData.errors.general.map((text, index) => (
                <div key={index}>
                  <PopoverComponent>{text}</PopoverComponent>
                </div>
              ))}
            </div>
            <div className="bg-lightSecondaryWarning" />
          </div>
        ) : null}
        {status != null && (
          <div className="absolute right-5 top-2/4 -translate-y-1/2 translate-x-[16px]">
            {status === 'loading' ? (
              <Loader width="20px" />
            ) : status === 'success' ? (
              <FontAwesomeIcon icon={ProRegularSvgIcons.faCheck} className="text-primaryGreen" size="lg" />
            ) : (
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faClose} className="text-lightSecondaryWarning" size="lg" />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
