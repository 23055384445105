import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import PortfolioIcon from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioSectionsPage(): JSX.Element | null {
  const { pathname } = useLocation()
  const t = useTranslatable()
  const breadcrumbsItems = [{ page: `${t('portfolio:dashboard')}`, path: pathname }]
  return (
    <Section title={t('portfolio:portfolio')} icon={<PortfolioIcon />} breadcrubms={breadcrumbsItems}>
      <Helmet title={t('portfolio:portfolio')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </Section>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const t = useTranslatable()
  const sections = useApi({
    endpoint: Api.getLecturerPortfolioSections,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })
  return (
    <>
      {sections.data.data.map((section) => (
        <div key={section.id} className="mt-2 text-primaryTextColor">
          <div className="items-cente flex">
            <div className="mb-3 ml-3 text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]">
              {section.name}
            </div>
            <div className="ml-3">
              <Link to={`/${locale}/lecturer/portfolio/sections/${section.id}/works`}>
                <Button variant="minimal" className="flex !h-[24px] items-center !px-1">
                  <span className="!text-xs !text-captionColor"> {t('portfolio:show_details')}</span>
                </Button>
              </Link>
            </div>
          </div>
          <Table.Table className="min-w-[768px]">
            <Table.Thead>
              <Table.Tr className="w-[100%">
                <Table.Th className="w-1/4 !text-center">
                  <span>{t('common:total')}</span>
                </Table.Th>
                <Table.Th className="w-1/4 !text-center">
                  <span>{t('portfolio:status_evaluated')}</span>
                </Table.Th>
                <Table.Th className="w-1/4 !text-center">
                  <span>{t('portfolio:status_pending')}</span>
                </Table.Th>
                <Table.Th className="w-1/4 !text-center">
                  <span>{t('portfolio:status_rejected')}</span>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td className="text-center">{section.workProgress?.total}</Table.Td>
                <Table.Td className="text-center">{section.workProgress?.evaluated}</Table.Td>
                <Table.Td className="text-center">{section.workProgress?.pending}</Table.Td>
                <Table.Td className="text-center">{section.workProgress?.rejected}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.Table>
        </div>
      ))}
    </>
  )
}
