import { t as tt } from 'i18next'
import env from 'src/helpers/env'
import { identity } from 'src/helpers/fns'
import { observablePromise, ObservablePromise } from 'src/helpers/observable'
import type { AnyHeaders } from 'src/hooks/auth/app'
import { refreshAuthObject } from 'src/hooks/auth/refreshAuthObject'
import { refreshTokenStorage } from 'src/hooks/auth/refreshTokenStorage'
import type { UploadConfig } from './UploadConfig'
import {
  AuthErrorDecoder,
  DataDecoder,
  ErrorMessageDecoder,
  ErrorsObjectDecoder,
  type AuthError,
  type ErrorMessage,
  type ErrorsObject,
} from './base'
import { getAuthorization } from './common'
import { MediaFileDecoder, type LanguageString, type MediaFile } from './types'

export interface postMediaFilesUploadParams {
  readonly headers: AnyHeaders
  readonly file: File
  readonly config: UploadConfig
}

export function postMediaFilesUpload({
  file,
  headers,
  config,
}: postMediaFilesUploadParams): ObservablePromise<MediaFile> {
  return observablePromise(async (progress, abortController) => {
    if (file.size > config.max_upload_size) {
      throw { type: 'ErrorMessage', message: tt('error:file_is_larger') }
    }

    const fileNameChunks = file.name.split('.')
    const ext = fileNameChunks[fileNameChunks.length - 1]!

    if (!config.allowed_extensions.includes(ext)) {
      throw { type: 'ErrorMessage', message: tt('error:file_extension_not_allowed') }
    }

    const chunkCount = Math.ceil(file.size / config.max_chunk_size)

    let mediaFile: MediaFile | undefined

    for (let i = 0; i < chunkCount && !abortController.signal.aborted; i++) {
      const start = i * config.max_chunk_size
      const end = Math.min(start + config.max_chunk_size, file.size)

      const headersObj = {
        Authorization: headers.Authorization,
        'Profile-ID': headers['Profile-ID'],
        'Accept-Language': headers['Accept-Language'],
      }

      const upload = uploadFile({
        index: i,
        config,
        file,
        headers: headersObj,
        uid: mediaFile?.id,
        slice: { start, end },
      })

      upload.progress.eventEmitter.addListener('update', (state) => {
        progress.setValue(i * (100 / chunkCount) + state / chunkCount)
      })

      mediaFile = await upload.promise
    }

    if (abortController.signal.aborted) {
      throw new Error('Upload Canceled!')
    } else if (mediaFile != null) {
      return mediaFile
    } else {
      throw new Error('File Not Found!')
    }
  })
}

interface UploadFileParams {
  readonly config: UploadConfig
  readonly file: File
  readonly index?: number
  readonly slice?: {
    readonly start: number
    readonly end: number
  }
  readonly uid?: string
  readonly headers: {
    readonly Authorization?: string
    readonly 'Profile-ID'?: string
    readonly 'Accept-Language': LanguageString
  }
}

function uploadFileL1({ file, slice, index, uid, headers, config }: UploadFileParams): ObservablePromise<MediaFile> {
  return observablePromise((progress, abortController) => {
    const { resolve, reject, promise } = Promise.withResolvers<MediaFile>()
    const data = new FormData()

    if (uid != null) {
      data.append('uid', uid)
    }

    if (index != null && config.url.startsWith(env.SERVICE_URL_V2)) {
      data.append('partNumber', index.toString())
    }

    const chunk =
      slice != null
        ? new File([file.slice(slice.start, slice.end)], file.name, {
            lastModified: file.lastModified,
            type: file.type,
          })
        : file

    data.append('file', chunk, file.name)

    const headersArray = Object.entries(headers)

    headersArray.push(['Accept', 'application/json'])

    if (slice != null) {
      headersArray.push(['Content-Range', `bytes ${slice.start}-${slice.end - 1}/${file.size}`])
    }

    const request = new XMLHttpRequest()

    request.open('POST', config.url)

    headersArray.forEach(([name, value]) => {
      request.setRequestHeader(name, value)
    })

    request.upload.addEventListener('progress', (e) => {
      progress.setValue((e.loaded / e.total) * 100)
    })

    request.addEventListener('load', () => {
      try {
        resolve(guardMediaFilesUpload(request.status, JSON.parse(request.response)))
      } catch (err) {
        reject(err)
      }
    })

    request.addEventListener('abort', reject)
    request.addEventListener('error', reject)
    request.addEventListener('timeout', reject)

    abortController.signal.addEventListener('abort', () => {
      request.abort()
    })

    request.send(data)

    return promise
  })
}

function uploadFile(params: UploadFileParams): ObservablePromise<MediaFile> {
  return observablePromise((progress, abortController) => {
    const { resolve, reject, promise } = Promise.withResolvers<MediaFile>()

    const refreshToken = refreshTokenStorage.getValue()?.authObject.refreshToken
    const authEnabled = params.headers.Authorization != null && refreshToken != null

    let request: ObservablePromise<MediaFile>

    function handleUpload(): void {
      if (abortController.signal.aborted) {
        reject(new Error('Upload Canceled!'))
      }

      request = uploadFileL1(
        authEnabled
          ? {
              ...params,
              headers: {
                ...params.headers,
                Authorization: getAuthorization(refreshTokenStorage.getValue()!.authObject),
              },
            }
          : params
      )
      request.progress.eventEmitter.on('update', progress.setValue)

      request.promise.then(resolve, (err) => {
        if ('type' in err && err.type === 'AuthError') {
          refreshAuthObject(refreshToken!).then(handleUpload, reject)
        } else {
          reject(err)
        }
      })
    }

    handleUpload()

    return promise
  })
}

function guardMediaFilesUpload(status: number, data: unknown): MediaFile {
  // 2XX MediaFile
  if (status >= 200 && status <= 299) {
    const result = DataDecoder(MediaFileDecoder).decode(data)
    if (result.ok) {
      return identity<MediaFile>(result.value)
    } else {
      console.error('POST /media-files/upload 2XX null\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // 401 AuthError
  if (status === 401) {
    const result = DataDecoder(AuthErrorDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<AuthError>(result.value)
    } else {
      console.error('POST /media-files/upload 401 AuthError\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // 422 ErrorsObject
  if (status === 422) {
    const result = DataDecoder(ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<ErrorsObject>(result.value)
    } else {
      console.error('GET /media-files/upload 422 ErrorsObject\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // XXX ErrorMessage
  {
    const result = DataDecoder(ErrorMessageDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<ErrorMessage>(result.value)
    } else {
      console.error('POST /media-files/upload XXX ErrorMessage\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // fallback ErrorMessage
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw identity<ErrorMessage>({
    type: 'ErrorMessage',
    message: 'Could not process response!',
  })
}
