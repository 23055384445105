import React from 'react'
import * as Api from 'src/api'
import { ScheduleLargeIcon } from 'src/assets/icons/customIcons/ScheduleLargeIcon'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { ScheduleList } from 'src/tailwind/components/ScheduleList'
import { Section } from 'src/tailwind/components/Section'
import SuspenseWrapper from '../includes/SuspenseWrapper'
import NoContent from './NoContent'

interface Props {
  isLecturer?: boolean
}

export default function HomeScheduleSection(props: Props): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <Section homePage title={t('schedule:today_schedule')} icon={<ScheduleLargeIcon />}>
      <SuspenseWrapper>
        <Schedule {...props} />
      </SuspenseWrapper>
    </Section>
  )
}
function Schedule({ isLecturer }: Props): React.ReactElement | null {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: isLecturer ? Api.getLecturerSchedulesCurrent : Api.getStudentSchedulesCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const schedule = data

  if (userState.data == null) return null
  const today = new Date().getDay()
  const todaySchedule = schedule.filter((schedule) => schedule.number === today)
  if (todaySchedule == null || todaySchedule.length === 0) {
    return <NoContent header={t('schedule:today_your_schedule_is_empty')} image={Warning} />
  }

  const baseRoute = `/${locale}/${isLecturer ? 'lecturer' : 'student'}/courses`
  return (
    <div className="overflow-x-scroll">
      <ul className="inline-block w-full min-w-[768px] list-none pl-0">
        {todaySchedule[0]?.schedules.map((schedule) => (
          <ScheduleList
            key={schedule.id}
            to={`${baseRoute}/${schedule.course.id}/groups`}
            time={schedule.hour.times}
            classroom={schedule.locationName ?? ''}
            lecture={schedule.group.lecturers}
            group={schedule.group.name}
            // finished
            online={schedule.lectureTypeName}
            course={schedule.course.fullName}
            data-testid={`schedule/${schedule.id}`}
          />
        ))}
      </ul>
    </div>
  )
}
