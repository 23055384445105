import { Helmet } from 'react-helmet-async'
import { LibraryLargeIcon } from 'src/assets/icons/customIcons/LibraryLarge'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import InfoCard from 'src/tailwind/components/InfoCard'
import { Section } from 'src/tailwind/components/Section'
import HomeScheduleSection from 'src/views/components/HomeScheduleSection'
import HomeNewsSection from '../news/HomeNewsSection'

export default function LecturerHomePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element {
  return (
    <div>
      <LibraryCountSection />
      <HomeNewsSection />
      <HomeScheduleSection isLecturer />
    </div>
  )
}

function LibraryCountSection(): JSX.Element {
  const t = useTranslatable()
  const userState = useUserState()
  const locale = useLocale()

  return (
    <Section homePage>
      <InfoCard
        title={t('library:quantity')}
        to={`/${locale}/library`}
        icon={<LibraryLargeIcon />}
        quantity={userState.data != null ? userState.data.libraryBalance : ''}
        className="w-full max-w-[450px]"
      />
    </Section>
  )
}
