import { Link } from 'react-router-dom'
import { type User } from 'src/api/types'
import { ArrowIcon } from 'src/assets/icons/customIcons/Arrow'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { UserIcon } from 'src/assets/icons/customIcons/menu-icons/User'
import { PinIcon } from 'src/assets/icons/customIcons/Pin'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  time: string
  classroom: string
  lecture: readonly User[] | undefined
  group: string
  finished?: boolean
  online: string
  course: string
  classNames?: {
    li?: string
  }
  to: string
}

export const ScheduleList = ({
  to,
  time,
  classroom,
  lecture,
  group,
  finished,
  online,
  course,
  classNames,
  ...props
}: Props): JSX.Element => {
  const t = useTranslatable()

  return (
    <li
      {...props}
      className={`hover-left-animation group relative flex cursor-pointer flex-col border-b-DEFAULT border-borderColor bg-card px-6 py-3 leading-none first:rounded-t-card last:rounded-b-card hover:bg-hover-card ${
        classNames?.li !== undefined ? classNames?.li : ''
      }`}
    >
      <Link to={to}>
        <div className="mb-2 flex text-caption">
          <div className="flex flex-[244px]">
            <div className="mr-3 flex items-center last:mr-0">
              <ClockIcon />
              <span className="ml-2" data-testid="time">
                {time}
              </span>
            </div>
            <div className="mr-3 flex items-center last:mr-0">
              <PinIcon />
              <span className="ml-2" data-testid="classroom">
                {classroom}
              </span>
            </div>
            <div className="mr-3 flex items-center last:mr-0 [&svg]:size-3">
              <UserIcon />
              <span className="ml-2">
                {lecture?.map((l) => (
                  <span key={l.id} className="ml-2 first:ml-0" data-testid={`lecturer/${l.id}`}>
                    {l.fullName}
                  </span>
                ))}
              </span>
            </div>
          </div>
          <div className="flex flex-[1px] justify-start">
            <span className="mr-4" data-testid="group">
              {group}
            </span>
            <span className="text-xs" data-testid="type">
              {online}
            </span>
          </div>
        </div>
        <div>
          <span className="font-semibold text-primaryTextColor" data-testid="course">
            {course}
          </span>
        </div>
        <div className="absolute right-[24px] top-1/2 flex -translate-y-1/2 items-center duration-500 ease-in-out group-hover:mr-[-10px]">
          {(finished ?? false) ? (
            <div className="mr-[44px] flex h-[24px] items-center justify-center rounded-[12px] bg-primaryYellow p-2">
              <span className="px-1 text-sm font-semi">{t('course:finished')}</span>
            </div>
          ) : null}
          <ArrowIcon />
        </div>
      </Link>
    </li>
  )
}
