import React from 'react'
import { toast } from 'react-toastify'
import { getMediaFilesDownload } from 'src/api'
import { actionProps } from 'src/helpers/fns'
import { AuthenticatedHeaders, useAnyHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  readonly mediaFileId: string
}

export const MediaFilesDownload = React.forwardRef<HTMLAnchorElement, JSX.IntrinsicElements['a'] & Props>(
  function MediaFilesDownload({ children, mediaFileId, ...props }, ref) {
    const headers = useAnyHeaders() as AuthenticatedHeaders
    const t = useTranslatable()

    return (
      <a
        {...props}
        ref={ref}
        {...actionProps(async () => {
          try {
            const downloadMediaFile = await getMediaFilesDownload({
              headers,
              args: {
                id: mediaFileId,
              },
            })

            location.replace(downloadMediaFile.url)
          } catch (error) {
            toast.error(t('error:an_error_occurred'))
          }
        })}
      >
        {children}
      </a>
    )
  }
)
