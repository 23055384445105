import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { AnsweredIcon } from 'src/assets/icons/customIcons/Answered'
import { RecievedIcon } from 'src/assets/icons/customIcons/Recieved'
import Feedback from 'src/assets/icons/customIcons/page-icons/Feedback'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import Select from 'src/views/components/Select'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerFeedbacksPage(): JSX.Element | null {
  const t = useTranslatable()
  const { pathname } = useLocation()
  const breadcrumbsItems = [{ page: `${t('feedback:feedback_messages')}`, path: pathname }]

  return (
    <Section title={t('feedback:feedback_messages')} icon={<Feedback />} breadcrubms={breadcrumbsItems}>
      <Helmet title={t('feedback:feedback_messages')} />

      <SuspenseWrapper>
        <>
          <Semesters />
          <FeedbacksTable />
        </>
      </SuspenseWrapper>
    </Section>
  )
}

function FeedbacksTable(): JSX.Element {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const activeSemester = useActiveSemester()
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams({
    page: '1',
    perPage: '10',
    semId: activeSemester?.id as string,
  })
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!
  const semId = searchParams.get('semId')

  const { data: feedbacks } = useApi({
    endpoint: Api.getLecturerFeedbacks,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
          page: parseInt(page),
          perPage: parseInt(perPage),
        },
      }),
      [headers, page, semId, perPage]
    ),
  })

  if (feedbacks?.data != null && feedbacks?.data.length === 0) {
    return (
      <NoContent
        image={Warning}
        header={t('error:items_not_found')}
        subHeader={t('error:try_adjusting_your_search_or_change_the_semester')}
        marginTop="5"
      />
    )
  }

  return (
    <div className="mb-7">
      <PaginationTop pagination={feedbacks.meta.pagination} />
      <Table.Table className="mt-7">
        <Table.Thead>
          <Table.Tr>
            <Table.Th className="!pl-6">{t('common:number')}</Table.Th>
            <Table.Th>{t('common:title')}</Table.Th>
            <Table.Th>{t('common:text')}</Table.Th>
            <Table.Th>{t('common:state')}</Table.Th>
            <Table.Th>{t('common:date')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {feedbacks.data.map((feedback) => (
            <tr key={feedback.id}>
              <Table.Td className="whitespace-nowrap">
                <Link
                  className="text-primaryBlueLink dark:text-primaryTextColor"
                  to={`${feedback.id}?semId=${activeSemester?.id ?? ''}`}
                >
                  {feedback.number}
                </Link>
              </Table.Td>
              <Table.Td>{feedback.subject.name}</Table.Td>
              <Table.Td>{feedback.text.length > 40 ? feedback.text.slice(0, 40) + '...' : feedback.text}</Table.Td>
              <Table.Td>
                {feedback.status === 1 ? (
                  <span className="flex items-center">
                    <RecievedIcon />
                    <span className="ml-2">{t('message:received')}</span>
                  </span>
                ) : (
                  <span className="flex items-center">
                    <AnsweredIcon />
                    <span className="ml-2">{t('message:answered')}</span>
                  </span>
                )}
              </Table.Td>
              <Table.Td className="whitespace-nowrap">
                {format(parseISO(feedback.date), 'd MMMM yyyy, HH:mm', {
                  locale: dateLocale,
                })}
              </Table.Td>
            </tr>
          ))}
        </Table.Tbody>
      </Table.Table>
      <PaginationBottom pagination={feedbacks.meta.pagination} />
    </div>
  )
}

function Semesters(): JSX.Element {
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const t = useTranslatable()

  const { data: semesters } = useApi({
    endpoint: Api.getLecturerFeedbacksSemesters,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const currentSemester = semesters.find((semester) => semester.id === semId)
  if (currentSemester == null) return <Loader className="m-auto flex" style={{ margin: 'auto' }} />

  return (
    <Select
      className="w-full"
      value={semesters.filter((semester) => semester.id === currentSemester.id)}
      options={semesters}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name + (option.isActive ? t('common:current') : '')}
      onChange={(option) =>
        setSearchParams((params) => {
          params.set('semId', option!.id)

          return params
        })
      }
    />
  )
}
