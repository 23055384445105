import { format, formatDistance, parseISO } from 'date-fns'
import { FormikConsumer } from 'formik'
import * as React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { type CoursePostComment } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useTheme } from 'src/state/providers/Theme'

import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import UserPhoto from 'src/views/components/UserPhoto'
import Swal from 'sweetalert2'

interface Props {
  post: Api.CoursePost
  setCommentsCount: React.Dispatch<React.SetStateAction<number>>
}

export default function Comments({ post, setCommentsCount }: Props): JSX.Element {
  const locale = useLocale()
  const [currentReplies, setCurrentReplies] = React.useState<Record<string, string>>({})
  const t = useTranslatable()
  const localeDate = useDateLocale()
  const auth = useAuth()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const semId = searchParams.get('semId')
  const headers = useAuthenticatedHeaders()
  const theme = useTheme()

  const { data: comments, mutate: fetchComments } = useApi({
    endpoint: Api.getLecturerCoursesDiscussionsComments,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
          postId: post.id,
        },
        query: {
          semId: +semId!,
        },
      }),
      [courseId, headers, post.id, semId]
    ),
    shouldFetch: semId != null && courseId != null,
  })

  const deleteComment = React.useCallback(
    async (commentId: string) => {
      try {
        await Api.deleteLecturerCoursesDiscussionsComments({
          headers,
          args: {
            id: courseId!,
            postId: post.id,
            commentId,
          },
          query: {
            semId: semId!,
          },
        })
        void fetchComments()
        setCommentsCount((prev) => prev - 1)

        toast.success(t('discussion:comment_successfully_deleted'))
      } catch (error) {
        toast.error(t('discussion:comment_not_deleted'))
      }
    },
    [courseId, fetchComments, headers, post.id, semId, setCommentsCount, t]
  )

  const postReplyComment = React.useCallback(
    async (body: string, commentId: string) => {
      try {
        await Api.postLecturerCoursesDiscussionsComments({
          headers,
          args: {
            id: courseId!,
            postId: post.id,
          },
          body: {
            body,
            replyCommentId: Number(commentId),
          },
          query: {
            semId: +semId!,
          },
        })
        void fetchComments()
        setCommentsCount((prev) => prev + 1)

        toast.success(t('discussion:comment_successfully_added'))
      } catch (error) {
        toast.error(t('discussion:comment_not_added'))
      }
    },
    [courseId, fetchComments, headers, post.id, semId, setCommentsCount, t]
  )

  const postComment = React.useCallback(
    async (body: string) => {
      try {
        await Api.postLecturerCoursesDiscussionsComments({
          headers,
          args: {
            id: courseId!,
            postId: post.id,
          },
          body: {
            body,
          },
          query: {
            semId: +semId!,
          },
        })
        void fetchComments()
        setCommentsCount((prev) => prev + 1)

        toast.success(t('discussion:comment_successfully_added'))
      } catch (error) {
        toast.error(t('discussion:comment_not_added'))
      }
    },
    [courseId, fetchComments, headers, post.id, semId, setCommentsCount, t]
  )

  return (
    <ul className="my-2 w-full list-none pl-[35px]">
      {comments?.data.map((comment: CoursePostComment) => (
        <li key={comment.id}>
          <div className="comment">
            <div className="my-2 flex w-full">
              <div className="mt-2">
                <UserPhoto user={comment.author} />
              </div>
              <div className="ml-2 w-full">
                <div className="rounded-card bg-card">
                  <div className="px-3 py-2">
                    <div>
                      <Link className="font-bold dark:text-white" to={`/${locale}/users/${comment.author!.uid}`}>
                        {comment.author!.fullName}
                      </Link>

                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {}
                      </a>
                    </div>
                    <div className="my-2 text-sm dark:text-white">{comment.body}</div>
                  </div>
                </div>
                <div className="ml-3 mt-1 text-captionColor">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setCurrentReplies((prev) => ({
                        ...prev,
                        [comment.id]: comment.author!.fullName,
                      }))
                    }}
                  >
                    {t('discussion:reply')}
                  </button>
                  {' ∙ '}
                  <button
                    onClick={(e) => {
                      e.preventDefault()

                      void (async () => {
                        const alert = await Swal.fire({
                          title: t('common:do_you_really_want_to_delete'),
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#0D6EFD',
                          cancelButtonColor: '#6C757D',
                          confirmButtonText: t('common:confirm'),
                          cancelButtonText: t('common:cancel'),
                        })
                        if (alert.isConfirmed) {
                          void deleteComment(comment.id)
                        }
                      })()
                    }}
                  >
                    {t('common:delete')}
                  </button>
                  {' ∙ '}
                  <a href="#" onClick={(e) => e.preventDefault()} data-tooltip-id={comment.id}>
                    <span>
                      {formatDistance(new Date(comment.createdAt), new Date(), {
                        addSuffix: true,
                        locale: localeDate,
                      })}
                    </span>
                    <Tooltip
                      id={comment.id}
                      place="top"
                      variant={theme === 'dark' ? 'dark' : 'light'}
                      className="mb-1 p-1"
                    >
                      <p className="mb-0" key={comment.createdAt}>
                        {format(parseISO(comment.createdAt), 'd MMMM yyyy, HH:mm', {
                          locale: localeDate,
                        })}
                      </p>
                    </Tooltip>
                  </a>
                  {comment.authorGroup !== undefined ? (
                    <>
                      {' ∙ '}
                      <span className="ml-1 rounded-md bg-card p-1 text-caption">{comment.authorGroup.name}</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {comment.children !== undefined ? (
              <div style={{ paddingLeft: 34 }}>
                <ul className="list-none">
                  {comment.children.map((subComment) => {
                    return (
                      <React.Fragment key={subComment.id}>
                        <li>
                          <div>
                            <div className="flex">
                              <div className="mt-2">
                                <UserPhoto user={subComment.author} />
                              </div>
                              <div className="ml-2 w-full">
                                <div className="rounded-card bg-card">
                                  <div className="px-3 py-2">
                                    <div>
                                      <Link
                                        className="font-bold dark:text-white"
                                        to={`/${locale}/users/${subComment.author!.uid}`}
                                      >
                                        {subComment.author!.fullName}
                                      </Link>

                                      <a href="#" onClick={(e) => e.preventDefault()}>
                                        {}
                                      </a>
                                    </div>
                                    <div className="p-2 text-sm dark:text-white">{subComment.body}</div>
                                  </div>
                                </div>
                                <div className="ml-3 mt-1 text-sm text-captionColor">
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setCurrentReplies((prev) => ({
                                        ...prev,
                                        [comment.id]: subComment.author!.fullName,
                                      }))
                                    }}
                                    className="text-sm text-captionColor"
                                  >
                                    {t('discussion:reply')}
                                  </button>
                                  {' ∙ '}
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()

                                      void (async () => {
                                        const alert = await Swal.fire({
                                          title: t('common:do_you_really_want_to_delete'),
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonColor: '#0D6EFD',
                                          cancelButtonColor: '#6C757D',
                                          confirmButtonText: t('common:confirm'),
                                          cancelButtonText: t('common:cancel'),
                                        })
                                        if (alert.isConfirmed) {
                                          void deleteComment(subComment.id)
                                        }
                                      })()
                                    }}
                                  >
                                    {t('common:delete')}
                                  </button>
                                  {' ∙ '}
                                  <button data-tooltip-id={subComment.id} onClick={(e) => e.preventDefault()}>
                                    <span>
                                      {formatDistance(new Date(subComment.createdAt), new Date(), {
                                        addSuffix: true,
                                        locale: localeDate,
                                      })}
                                    </span>
                                    <Tooltip
                                      id={subComment.id}
                                      place="top"
                                      variant={theme === 'dark' ? 'dark' : 'light'}
                                      className="mb-1 p-1"
                                    >
                                      <p className="mb-0" key={subComment.createdAt}>
                                        {format(parseISO(subComment.createdAt), 'd MMMM yyyy, HH:mm', {
                                          locale: localeDate,
                                        })}
                                      </p>
                                    </Tooltip>
                                  </button>
                                  {subComment.authorGroup !== undefined ? (
                                    <>
                                      {' ∙ '}
                                      <span className="ml-1 rounded-md bg-card p-1 text-caption">
                                        {subComment.authorGroup.name}
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </React.Fragment>
                    )
                  })}
                </ul>
              </div>
            ) : null}
            <CommentReply currentReplies={currentReplies} comment={comment} postReplyComment={postReplyComment} />
          </div>
        </li>
      ))}
      {auth.state === 'Authenticated' && (
        <li>
          <div>
            <div className="mt-2 flex">
              <div>
                <UserPhoto user={auth.user} />
              </div>
              <div className="ml-2 w-full">
                <div className="relative">
                  <Form
                    initialValues={{ body: '' }}
                    onSubmit={async ({ body }, { resetForm }) => {
                      await postComment(body)
                      resetForm()
                    }}
                    isConfirmable
                    enableReinitialize
                  >
                    <FormikConsumer>
                      {(formik) => (
                        <TextInput
                          name="body"
                          autoFocus
                          type="textarea"
                          className={`border border-primaryGray focus:border-buttonBlue focus:outline-none ${
                            formik.isSubmitting ? 'pointer-events-none opacity-50' : ''
                          }`}
                          placeholder={t('discussion:leave_a_comment_here')}
                          sendOrDelete
                        />
                      )}
                    </FormikConsumer>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}

interface ICommentReplyProps {
  currentReplies: Record<string, string>
  comment: Api.CoursePostComment
  postReplyComment: (body: string, commentId: string) => Promise<void>
}

function CommentReply({ currentReplies, comment, postReplyComment }: ICommentReplyProps) {
  const auth = useAuth()
  const t = useTranslatable()
  const authorName = currentReplies[comment.id]
  if (!authorName) return null

  return (
    <div style={{ paddingLeft: 34 }}>
      <div>
        <div className="mt-2 flex">
          <div>
            <UserPhoto user={auth.user} />
          </div>
          <div className="ml-2 w-full">
            <div className="relative">
              <Form
                initialValues={{ body: `@${authorName ?? ''} ` }}
                onSubmit={async ({ body }, { resetForm }) => {
                  await postReplyComment(body, comment.id)
                  resetForm()
                }}
                isConfirmable
                enableReinitialize
              >
                <FormikConsumer>
                  {(formik) => (
                    <TextInput
                      name="body"
                      autoFocus
                      type="textarea"
                      className={`border border-primaryGray focus:border-buttonBlue focus:outline-none ${
                        formik.isSubmitting ? 'pointer-events-none opacity-50' : ''
                      }`}
                      placeholder={t('discussion:leave_a_comment_here')}
                      sendOrDelete
                    />
                  )}
                </FormikConsumer>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
