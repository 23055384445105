import EventEmitter from 'eventemitter3'
import { FormikErrors } from 'formik'
import React, { useMemo } from 'react'
import type * as Api from 'src/api'
import { postMediaFilesUpload } from 'src/api/mediaFileUpload'
import { identity } from 'src/helpers/fns'
import { useAnyHeaders } from './auth/app'

export interface FileUploading {
  readonly status: 'UPLOADING'
  readonly localFile: File
  readonly remoteFile?: undefined
  readonly progress: number
  readonly message?: undefined
}

export interface FileFailed {
  readonly status: 'FAILED'
  readonly localFile: File
  readonly remoteFile?: undefined
  readonly progress: number
  readonly message: string
}
export interface FileDone {
  readonly status: 'DONE'
  readonly localFile?: File
  readonly remoteFile: Api.MediaFile
  readonly progress?: undefined
  readonly message?: undefined
}

export type FileState = FileUploading | FileFailed | FileDone

export interface Actions {
  readonly FILE_ADD: (payload: File) => void
  readonly FILE_REMOVE: (payload: { readonly index: number }) => void
}

export interface FileUploadArgs {
  readonly config: Api.UploadConfig
  readonly state: readonly FileState[]
  readonly setState: (
    value: readonly FileState[],
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<readonly FileState[]>> | unknown
}

export function useFileUpload({ config, state, setState }: FileUploadArgs): [readonly FileState[], Actions] {
  const stateRef = React.useRef(state)
  stateRef.current = state

  const events = useMemo(() => new EventEmitter<Actions>(), [])

  const headers = useAnyHeaders()
  const headersRef = React.useRef(headers)
  headersRef.current = headers

  const FILE_REMOVE = React.useCallback(
    ({ index }: { readonly index: number }) => {
      events.emit('FILE_REMOVE', { index })

      setState([...stateRef.current.slice(0, index), ...stateRef.current.slice(index + 1)])
    },
    [events, setState]
  )

  const FILE_UPLOAD_DONE = React.useCallback(
    ({ index, file }: { readonly index: number; readonly file: Api.MediaFile }) => {
      stateRef.current = [
        ...stateRef.current.slice(0, index),
        {
          status: 'DONE',
          remoteFile: file,
          localFile: stateRef.current[index]!.localFile!,
        },
        ...stateRef.current.slice(index + 1),
      ]

      setState(stateRef.current)
    },
    [setState]
  )
  const FILE_UPLOAD_FAILED = React.useCallback(
    ({ index, message }: { readonly index: number; readonly message: string }) => {
      stateRef.current = [
        ...stateRef.current.slice(0, index),
        {
          status: 'FAILED',
          message,
          progress: stateRef.current[index]!.progress!,
          localFile: stateRef.current[index]!.localFile!,
        },
        ...stateRef.current.slice(index + 1),
      ]

      setState(stateRef.current)
    },
    [setState]
  )
  const FILE_UPLOAD_PROGRESS = React.useCallback(
    ({ index, progress }: { readonly index: number; readonly progress: number }) => {
      stateRef.current = [
        ...stateRef.current.slice(0, index),
        {
          status: 'UPLOADING',
          progress,
          localFile: stateRef.current[index]!.localFile!,
        },
        ...stateRef.current.slice(index + 1),
      ]

      setState(stateRef.current)
    },
    [setState]
  )
  const FILE_UPLOAD_START = React.useCallback(
    async ({ index }: { readonly index: number }) => {
      stateRef.current = [
        ...stateRef.current.slice(0, index),
        {
          status: 'UPLOADING',
          progress: 0,
          localFile: stateRef.current[index]!.localFile!,
        },
        ...stateRef.current.slice(index + 1),
      ]

      setState(stateRef.current)

      const fileState = stateRef.current[index]! as FileUploading
      let canceled = false

      let handleFileRemove: Actions['FILE_REMOVE'] | null = null

      try {
        const upload = postMediaFilesUpload({
          config,
          headers: headersRef.current,
          file: fileState.localFile,
        })

        handleFileRemove = (payload) => {
          if (payload.index === index) {
            canceled = true
            upload.abortController.abort()

            if (handleFileRemove != null) {
              events.removeListener('FILE_REMOVE', handleFileRemove)
            }
          }
        }

        events.addListener('FILE_REMOVE', handleFileRemove)

        upload.progress.eventEmitter.addListener('update', (progress) => {
          if (!canceled) {
            FILE_UPLOAD_PROGRESS({ index, progress })
          }
        })

        const file = await upload.promise

        if (!canceled) {
          FILE_UPLOAD_DONE({ index, file })
        }
      } catch (_err: any) {
        console.error(_err)

        if (!canceled) {
          const err = identity<Api.AuthError | Api.ErrorMessage | Api.ErrorsObject>(_err)
          let message = 'File Upload Failed!'

          if (['AuthError', 'ErrorMessage', 'ErrorsObject'].includes(err.type)) {
            if (err.type === 'ErrorsObject') {
              message = err.errors.file?.[0] ?? message
            } else {
              message = err.message
            }
          }

          FILE_UPLOAD_FAILED({
            index,
            message,
          })
        }
      } finally {
        if (handleFileRemove != null) {
          events.removeListener('FILE_REMOVE', handleFileRemove)
        }
      }
    },
    [FILE_UPLOAD_DONE, FILE_UPLOAD_FAILED, FILE_UPLOAD_PROGRESS, config, events, setState]
  )

  const FILE_ADD = React.useCallback(
    (payload: File) => {
      stateRef.current = [
        ...stateRef.current,
        {
          status: 'UPLOADING',
          localFile: payload,
          progress: 0,
        },
      ]

      setState(stateRef.current)

      const newFileIndex = stateRef.current.length - 1

      FILE_UPLOAD_START({ index: newFileIndex })
    },
    [FILE_UPLOAD_START, setState]
  )

  return [state, { FILE_ADD, FILE_REMOVE }]
}
